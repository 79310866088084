import { Box, Button, Flex, Grid, Heading, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { FaMagic, FaAngleDoubleDown, FaAngleDoubleUp, FaFileAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { WalletsTable } from '@features/panels/walletsPanel/WalletsPanel.component';
import { formatDate, StatusSelector, Attachments } from '@features/profile-overview/';
import AuditLogModal from '@features/profile-overview/components/AuditLogModal.component';
import EditableField from '@features/profile-overview/components/EditableField/';
import {
  AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS,
  AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS,
  LICENSING_REGISTRATION_STATUS_OPTIONS,
  REGULATORY_INVESTIGATION_STATUS_OPTIONS
} from '@features/profile-wizard/components/entity/entityOptions';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  useGetCountriesQuery,
  useGetEntityTypesQuery,
  useGetGendersQuery,
  useGetIdTypesQuery,
  useGetNatureOfEmploymentQuery,
  useGetProfileByIdQuery,
  useGetProfileGroupsQuery,
  useGetProfileRiskRatingsQuery,
  useUpdateProfileMutation
} from '@services/canaria.services';

import MultipleChoiceField from './MultipleChoiceField';
import ProfileGroupOverview from './ProfileGroupOverview';
import ProfileNotes from './ProfileNotes';
import RelatedPartiesSection from './RelatedPartiesSection';

const PrimaryPartyInformationEntity: React.FC<{ profile: IProfile }> = ({ profile }) => {
  const activeOrgID = useSelector(selectActiveOrgID);

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const [updateProfile] = useUpdateProfileMutation();
  const { data: countries = [] } = useGetCountriesQuery(null);
  const { data: entityTypes = [] } = useGetEntityTypesQuery(null);
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const handleFieldChange = (name: string) => async (value) => {
    await updateProfile({
      orgId: activeOrgID,
      profileId: profile.id,
      [name]: value
    }).unwrap();
  };

  return (
    <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6} width="100%">
      <VStack spacing={6}>
        <EditableField
          label="Full Legal Name"
          value={profile?.fullLegalName}
          onConfirmChange={handleFieldChange('fullLegalName')}
          type="text"
        />

        <EditableField
          label="DBA/Trade Name"
          value={profile?.dbaTradeName}
          onConfirmChange={handleFieldChange('dbaTradeName')}
          type="text"
        />
        <EditableField label="Email" value={profile?.email} onConfirmChange={handleFieldChange('email')} type="text" />
        <EditableField
          label="Entity Formation Type"
          value={profile?.entityFormationType}
          type="select"
          onConfirmChange={handleFieldChange('entityFormationType')}
          options={entityTypes}
        />
        <EditableField
          label="Place of Establishment"
          value={profile?.placeOfEstablishment?.id}
          onConfirmChange={handleFieldChange('placeOfEstablishment')}
          type="select"
          options={mappedCountries}
        />
        <EditableField
          label="Date of Establishment"
          value={profile?.dateOfEstablishment}
          onConfirmChange={handleFieldChange('dateOfEstablishment')}
          type="date"
        />
        <EditableField
          label="Unique Identification Number"
          value={profile?.uniqueIdentificationNumber}
          onConfirmChange={handleFieldChange('uniqueIdentificationNumber')}
          type="text"
        />
        <EditableField
          label="Registered Business Address"
          value={profile?.registeredBusinessAddress}
          onConfirmChange={handleFieldChange('registeredBusinessAddress')}
          type="text"
        />
        <EditableField
          label="Mailing Address"
          value={profile?.mailingAddress}
          onConfirmChange={handleFieldChange('mailingAddress')}
          type="text"
        />
        <EditableField
          label="Physical Address"
          value={profile?.physicalAddress}
          onConfirmChange={handleFieldChange('physicalAddress')}
          type="text"
        />
        <EditableField
          label="Company website"
          value={profile?.companyWebsite}
          onConfirmChange={handleFieldChange('companyWebsite')}
          type="text"
        />
        <EditableField
          label="PEP Declaration"
          value={profile?.pepDeclaration?.toString()}
          type="select"
          options={[
            { value: 'true', name: 'Yes' },
            { value: 'false', name: 'No' }
          ]}
          onConfirmChange={handleFieldChange('pepDeclaration')}
        />
        <EditableField
          label="PEP Name"
          value={profile?.pepName}
          onConfirmChange={handleFieldChange('pepName')}
          type="text"
        />

        <EditableField
          label="PEP Position"
          value={profile?.pepPosition}
          onConfirmChange={handleFieldChange('pepPosition')}
          type="text"
        />
        <EditableField
          label="PEP Country"
          value={profile?.pepCountry?.id}
          onConfirmChange={handleFieldChange('pepCountry')}
          type="select"
          options={mappedCountries}
        />
        <EditableField
          label="Point of Contact Name"
          value={profile?.pointOfContactName}
          onConfirmChange={handleFieldChange('pointOfContactName')}
          type="text"
        />
        <EditableField
          label="Ownership Percentage"
          value={profile?.ownershipPercentage}
          onConfirmChange={handleFieldChange('ownershipPercentage')}
          type="text"
        />
        <EditableField
          label="Age Eligibility Compliance"
          value={profile?.ageEligibilityCompliance?.toString()}
          onConfirmChange={handleFieldChange('ageEligibilityCompliance')}
          type="select"
          options={[
            { value: 'true', name: 'Yes' },
            { value: 'false', name: 'No' }
          ]}
        />
        <EditableField
          label="Privacy Compliance Existence"
          value={profile?.privacyComplianceExistence?.toString()}
          onConfirmChange={handleFieldChange('privacyComplianceExistence')}
          type="select"
          options={[
            { value: 'true', name: 'Yes' },
            { value: 'false', name: 'No' }
          ]}
        />
      </VStack>
      {profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Business Regulatory Compliance')
        ?.entity === true && (
        <VStack spacing={6}>
          <EditableField
            label="Nature of Business"
            value={profile?.natureOfBusiness}
            onConfirmChange={handleFieldChange('natureOfBusiness')}
            type="text"
          />
          <EditableField
            label="Regulatory Investigation Status"
            value={profile?.regulatoryInvestigationStatus}
            onConfirmChange={handleFieldChange('regulatoryInvestigationStatus')}
            type="select"
            options={REGULATORY_INVESTIGATION_STATUS_OPTIONS.map((option) => ({
              value: option.value,
              name: option.label
            }))}
          />
          <EditableField
            label="Regulatory Investigation Details"
            value={profile?.regulatoryInvestigationDetails}
            onConfirmChange={handleFieldChange('regulatoryInvestigationDetails')}
            type="text"
          />
          <EditableField
            label="Licensing/Registration Status"
            value={profile?.licensingRegistrationStatus}
            onConfirmChange={handleFieldChange('licensingRegistrationStatus')}
            type="select"
            options={LICENSING_REGISTRATION_STATUS_OPTIONS.map((option) => ({
              value: option.value,
              name: option.label
            }))}
          />
          <EditableField
            label="Licensing/Registration Information"
            value={profile?.licensingRegistrationInformation}
            onConfirmChange={handleFieldChange('licensingRegistrationInformation')}
            type="text"
          />
          <EditableField
            label="AML/Sanctions Program Required?"
            value={Number(profile?.amlSanctionsProgramRequired)}
            onConfirmChange={handleFieldChange('amlSanctionsProgramRequired')}
            type="select"
            options={AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS.map((option) => ({
              value: Number(option.value),
              name: option.label
            }))}
          />
          <MultipleChoiceField
            label="AML/Sanctions Program Elements"
            placeholder="Select an element..."
            profile={profile}
            options={AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS}
            handleFieldChange={handleFieldChange}
            fieldLabel="amlSanctionsProgramElements"
            labelMapping={true}
          />
          <EditableField
            label="AML/Sanctions Program Comments"
            value={profile?.amlSanctionsProgramComments}
            onConfirmChange={handleFieldChange('amlSanctionsProgramComments')}
            type="text"
          />
          <MultipleChoiceField
            label="Business Operations Footprint"
            placeholder="Select a country..."
            profile={profile}
            options={mappedCountries.map((country) => ({
              value: country.value,
              label: country.name
            }))}
            handleFieldChange={handleFieldChange}
            fieldLabel="businessOperationsFootprint"
          />
          <MultipleChoiceField
            label="High Risk Jurisdiction Footprint"
            placeholder="Select a country..."
            profile={profile}
            options={mappedCountries.map((country) => ({
              value: country.value,
              label: country.name
            }))}
            handleFieldChange={handleFieldChange}
            fieldLabel="highRiskJurisdictionFootprint"
          />
        </VStack>
      )}
    </Grid>
  );
};

const ProfileOverview: React.FC = () => {
  const { profileId } = useParams();
  const [updateProfile] = useUpdateProfileMutation();
  const [isPrimaryPartyInfoOpen, setIsPrimaryPartyInfoOpen] = useState(true);
  const [isAuditLogOpen, setIsAuditLogOpen] = useState(false);

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const { data: profile, isLoading } = useGetProfileByIdQuery({
    orgId: activeOrgID,
    profileId
  });

  const { data: profileGroups = [] } = useGetProfileGroupsQuery({
    orgId: activeOrgID
  });

  const { data: countries = [] } = useGetCountriesQuery({});
  const { data: genders = [] } = useGetGendersQuery({});
  const { data: idTypes = [] } = useGetIdTypesQuery({});
  const { data: profileRiskRatings = [] } = useGetProfileRiskRatingsQuery({});
  const { data: natureOfEmployment = [] } = useGetNatureOfEmploymentQuery({});

  if (isLoading || profile == null) {
    return <Loading message="Loading Profile details..." />;
  }
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const handleFieldChange = (name: string) => async (value) => {
    await updateProfile({
      orgId: activeOrgID,
      profileId,
      [name]: value
    }).unwrap();
  };
  const relatedPartiesStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Related Parties');
  const relatedPartiesEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? relatedPartiesStep?.individual : relatedPartiesStep?.entity;

  const walletsStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Add Wallets');
  const walletsEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? walletsStep?.individual : walletsStep?.entity;

  const expectedTransactionActivityStep = profile.profileGroup.stepsProfileForm.steps.find(
    ({ step }) => step === 'Expected Transaction Activity'
  );
  const expectedTransactionActivityEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL
      ? expectedTransactionActivityStep?.individual
      : expectedTransactionActivityStep?.entity;

  return (
    <>
      <Heading as="h1" size="lg" textAlign="center" mb={5}>
        Due Diligence Profile
      </Heading>
      <Flex justifyContent="center" alignItems="center" width="full" paddingX={5} mb={8}>
        <Box flex={1} />
        <Box display="flex" alignItems="center" flex={1} justifyContent="center">
          <Text mr={4} fontWeight={600}>
            Status
          </Text>
          <StatusSelector profileId={profileId} status={profile.status} hasOpenItems={profile.hasOpenItems} />
        </Box>
        <Box display="flex" flex={1} justifyContent="end">
          <Button
            ml={4}
            leftIcon={<Icon as={FaFileAlt} />}
            color="black"
            bg="white"
            border="1px"
            borderColor="gray.200"
            onClick={() => {
              setIsAuditLogOpen(true);
            }}
          >
            Profile Audit Log
          </Button>
          <Link to={`/dashboard/profile-wizard?profileId=${profileId}&step=1`}>
            <Button ml={4} leftIcon={<Icon as={FaMagic} />} color="white" bg="gray.800">
              Go to Wizard
            </Button>
          </Link>
        </Box>
      </Flex>

      <Flex justifyContent="space-between" pr={5} width="100%">
        <Box flex={2} pr={3}>
          <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
            <Flex justifyContent="space-between" width="full">
              <Heading as="h1" size="md" textAlign="left" mb={5}>
                Overview
              </Heading>
              <Text fontSize="sm" color="gray.500" fontStyle="italic">
                {profile != null && (
                  <>
                    Created at {formatDate(profile.createdAt)} | Update at: {formatDate(profile.updatedAt)}
                  </>
                )}
              </Text>
            </Flex>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
              <EditableField
                label="Name"
                value={profile?.name}
                onConfirmChange={handleFieldChange('name')}
                type="text"
              />
              <EditableField
                label="Type"
                value={profile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity'}
                isDisabled={true}
                onConfirmChange={() => {}}
                type="text"
              />
              <Flex>
                <EditableField
                  label="Profile group"
                  value={profile?.profileGroup.id}
                  type="select"
                  options={profileGroups?.map((group) => ({
                    value: group.id,
                    name: group.name
                  }))}
                  onConfirmChange={handleFieldChange('profile_group')}
                />
                <ProfileGroupOverview profileGroupId={profile?.profileGroup.id} />
              </Flex>
              <EditableField
                label="Profile risk"
                value={profile?.risk}
                type="select"
                options={profileRiskRatings}
                onConfirmChange={handleFieldChange('risk')}
                allowNull={false}
              />
            </Grid>
          </VStack>
          <VStack
            spacing={4}
            align="stretch"
            m={4}
            p={4}
            boxShadow="md"
            bg="white"
            w="100%"
            border={profile.totalUnresolvedHits > 0 ? '3px solid' : ''}
            borderColor={profile.totalUnresolvedHits > 0 ? 'yellow.400' : ''}
            borderRadius="md"
          >
            <Flex justifyContent="space-between" width="full" alignItems="center">
              <Heading as="h1" size="md" textAlign="left">
                Primary Party Information
              </Heading>
              <Flex alignItems="center">
                <Text fontSize="sm" color="red.500" fontWeight="bold" mr={2}>
                  {profile.totalUnresolvedHits > 0 ? `${profile.totalUnresolvedHits} unresolved hits` : ''}
                </Text>
                <Link to={`/dashboard/profiles/${profileId}/screenings`}>
                  <Button size="sm" color="white" background="gray.800" mr={2}>
                    Screening hits
                  </Button>
                </Link>
                <IconButton
                  aria-label="Toggle Primary Party Information"
                  icon={isPrimaryPartyInfoOpen ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setIsPrimaryPartyInfoOpen(!isPrimaryPartyInfoOpen);
                  }}
                />
              </Flex>
            </Flex>
            {isPrimaryPartyInfoOpen &&
              (profile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
                <>
                  <EditableField
                    label="Full legal name"
                    value={profile?.fullLegalName}
                    onConfirmChange={handleFieldChange('fullLegalName')}
                    type="text"
                  />
                  <EditableField
                    label="Email"
                    value={profile?.email}
                    onConfirmChange={handleFieldChange('email')}
                    type="text"
                  />
                  <EditableField
                    label="Gender"
                    value={profile?.gender?.value}
                    onConfirmChange={handleFieldChange('gender')}
                    type="select"
                    options={genders}
                  />
                  <EditableField
                    label="Date of birth"
                    value={profile?.dateOfBirth}
                    onConfirmChange={handleFieldChange('dateOfBirth')}
                    type="date"
                  />
                  <EditableField
                    label="Citizenship"
                    value={profile?.citizenship?.id}
                    onConfirmChange={handleFieldChange('citizenship')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="Country of Residence"
                    value={profile?.countryOfResidence?.id}
                    onConfirmChange={handleFieldChange('countryOfResidence')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="State"
                    value={profile?.state}
                    onConfirmChange={handleFieldChange('state')}
                    type="text"
                  />
                  <EditableField
                    label="City"
                    value={profile?.city}
                    onConfirmChange={handleFieldChange('city')}
                    type="text"
                  />

                  <EditableField
                    label="Postal code"
                    value={profile?.postalCode}
                    onConfirmChange={handleFieldChange('postalCode')}
                    type="text"
                  />
                  <EditableField
                    label="Address"
                    value={profile?.address}
                    onConfirmChange={handleFieldChange('address')}
                    type="text"
                  />
                  <EditableField
                    label="Place of Birth"
                    value={profile?.placeOfBirth?.id}
                    onConfirmChange={handleFieldChange('placeOfBirth')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="ID Issuer"
                    value={profile?.idIssuer?.id}
                    onConfirmChange={handleFieldChange('idIssuer')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="ID type"
                    value={profile?.idType?.value}
                    onConfirmChange={handleFieldChange('idType')}
                    type="select"
                    options={idTypes}
                  />
                  <EditableField
                    label="ID number"
                    value={profile?.idNumber}
                    onConfirmChange={handleFieldChange('idNumber')}
                    type="text"
                  />
                  <EditableField
                    label="Nature of employment"
                    value={profile?.natureOfEmployment}
                    onConfirmChange={handleFieldChange('natureOfEmployment')}
                    type="select"
                    options={natureOfEmployment}
                  />
                  <EditableField
                    label="PEP Declaration"
                    value={profile?.pepDeclaration?.toString()}
                    type="select"
                    options={[
                      { value: 'true', name: 'Yes' },
                      { value: 'false', name: 'No' }
                    ]}
                    onConfirmChange={handleFieldChange('pepDeclaration')}
                  />
                  <EditableField
                    label="PEP Position"
                    value={profile?.pepPosition}
                    onConfirmChange={handleFieldChange('pepPosition')}
                    type="text"
                  />
                  <EditableField
                    label="PEP Country"
                    value={profile?.pepCountry?.id}
                    onConfirmChange={handleFieldChange('pepCountry')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="Control person title"
                    value={profile?.controlPersonTitle}
                    onConfirmChange={handleFieldChange('controlPersonTitle')}
                    type="text"
                  />
                  <EditableField
                    label="Ownership percentage"
                    value={profile?.ownershipPercentage}
                    onConfirmChange={handleFieldChange('ownershipPercentage')}
                    type="text"
                  />
                </>
              ) : (
                <PrimaryPartyInformationEntity profile={profile} />
              ))}
          </VStack>
          {expectedTransactionActivityEnabled === true && (
            <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
              <Flex justifyContent="space-between" width="full">
                <Heading as="h1" size="md" textAlign="left" mb={5}>
                  Expected Transaction Activity
                </Heading>
              </Flex>

              <>
                <EditableField
                  label="Purpose for establishing account"
                  value={profile?.purposeForEstablishingAccount}
                  onConfirmChange={handleFieldChange('purposeForEstablishingAccount')}
                  type="text"
                />
                <EditableField
                  label="Expected nature of activity"
                  value={profile?.expectedNatureOfActivity}
                  onConfirmChange={handleFieldChange('expectedNatureOfActivity')}
                  type="text"
                />
                <EditableField
                  label="Source of funds"
                  value={profile?.sourceOfFunds}
                  onConfirmChange={handleFieldChange('sourceOfFunds')}
                  type="text"
                />
                <EditableField
                  label="Flow of funds"
                  value={profile?.flowOfFunds}
                  onConfirmChange={handleFieldChange('flowOfFunds')}
                  type="text"
                />
                <EditableField
                  label="Activity type"
                  value={profile?.activityType}
                  onConfirmChange={handleFieldChange('activityType')}
                  type="select"
                  options={[
                    { value: 'one-time', name: 'One-time' },
                    { value: 'ongoing', name: 'Ongoing' }
                  ]}
                />
                <EditableField
                  label="Average monthly # transactions"
                  value={profile?.averageMonthlyTxn}
                  onConfirmChange={handleFieldChange('averageMonthlyTxn')}
                  type="text"
                />
                <EditableField
                  label="Average monthly $ value txns"
                  value={profile?.averageMonthlyValueTxn}
                  onConfirmChange={handleFieldChange('averageMonthlyValueTxn')}
                  type="text"
                />
                <EditableField
                  label="Expected transaction activity comments"
                  value={profile?.expectedTransactionActivityComments}
                  onConfirmChange={handleFieldChange('expectedTransactionActivityComments')}
                  type="text"
                />
              </>
            </VStack>
          )}
        </Box>
        <Box flex={1} pr={2}>
          <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
            <ProfileNotes profileId={profileId} />
          </VStack>
          <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
            <Attachments profileId={profileId} orgId={activeOrgID} />
          </VStack>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" paddingX={4} width="100%" direction="column">
        {relatedPartiesEnabled === true && (
          <VStack spacing={4} align="stretch" mb={4} p={4} boxShadow="md" bg="white" w="100%">
            <Heading as="h1" size="md" textAlign="left" mb={5}>
              <Text mb="2">Related Parties</Text>
              <RelatedPartiesSection orgId={activeOrgID} profileId={profileId} />
            </Heading>
          </VStack>
        )}
        {walletsEnabled === true && (
          <VStack spacing={4} align="stretch" mb={4} p={4} boxShadow="md" bg="white" w="100%">
            <Heading as="h1" size="md" textAlign="left" mb={5}>
              <Text mb="2">Wallets</Text>
              <WalletsTable profileId={profileId} />
            </Heading>
          </VStack>
        )}
      </Flex>

      <AuditLogModal
        isOpen={isAuditLogOpen}
        onClose={() => {
          setIsAuditLogOpen(false);
        }}
        profileId={profileId}
      />
    </>
  );
};

export default ProfileOverview;
